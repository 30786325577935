.scroll-container {
  display: flex;
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 20px;
  user-select: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: grab;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scrollWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-container:active {
  cursor: grabbing;
}

.scroll-item {
  flex: 0 0 auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: fit-content;
  height: fit-content;
  margin: 0 10px;
}

.scroll-item img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}