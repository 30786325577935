/* Table.module.css */

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  caption-side: bottom;
  font-size: 0.875rem;
  line-height: 1.5;
}

.table-caption {
  margin-top: 1rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.table-header {
  border-bottom: 1px solid #e5e7eb;
}

.table-head {
  height: 2.75rem;
  padding: 0.75rem;
  color: #6b7280;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
}

.table-body {
  border: none;
}

.table-row {
  border-bottom: 1px solid #e5e7eb;
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: #f9fafb;
}

.table-cell {
  padding: 0.75rem;
  vertical-align: middle;
}

.table-footer {
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
  font-weight: 500;
}

.table-footer .table-cell {
  color: #111827;
}