.horizontalScroll {
  gap: 15px;
}

.thingsToDoItem {
  width: 360px;
  height: 580px;
  max-width: 100vw;
  position: relative;

  @media screen and (min-width: 768px) {
      width: 460px;
  }
}

.thingsToDoItemShort {
  height: 490px;
}

.imageBackground{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background-color 300ms ease-in-out;
  background: rgb(0,0,1);
  background: linear-gradient(0deg, rgba(0,0,1,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
}

.title{
  position: absolute;
  top: 50%;
  width: 252px;
  font-family: var(--header-font);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
  letter-spacing: 4px;
  color: white;
}


.scrollRight {
  position: absolute;
  top: 50%;
  right: 5%;
  color: white;
  font-size: 70px;
  transform: translateY(-50%);
  transition: opacity 300ms ease-in-out;
  animation: bounceHorizontal 2s infinite ease-in-out;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.8));
  @media screen and (max-width: 768px) {
    font-size: 50px;
}
}


@keyframes bounceHorizontal {
  0%, 100% {
      transform: translate(0, -50%);
  }
  50% {
      transform: translate(10px, -50%);
  }
}

.content{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 48%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0;
  transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
  height: fit-content;
}

.contentTitle{
  font-family: var(--header-font);
  font-size: 36px;
  width: 252px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  letter-spacing: 4px;
  color: white;
}

.thingsToDoItem:hover .title{
  opacity: 0;
  top: 45%;
}

.button{
  position: absolute;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: bottom 300ms ease-in-out, opacity 100ms ease-in-out;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
}

.thingsToDoItem:hover .imageBackground{
  background-color: rgba(0,0,0,0.5);
}

.thingsToDoItem:hover .content{
  top: 45%;
  opacity: 1;
}

.thingsToDoItem:hover .button{
  opacity: 1;
  bottom: 50px;
}

.description{
  display: flex;
  justify-content: center;
}

.description>p:first-of-type {
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionShort>p:first-of-type{
  -webkit-line-clamp: 5;
}

.description>p {
  display: none;
}