.weather_chart{
  width: 100%;
  height: 100%;
  text-align: center;

  &>canvas {
    margin-bottom: 20px;
  }
}

.loading_chart{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--color-red);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}